import React from 'react';
import styled from 'styled-components';

const Title = ({ title, subtitle }) => {
  return (
    <TitleWrapper>
      <h2 className="header">
        <span className="title">{title}</span>
        <span>{subtitle}</span>
      </h2>
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  text-transform: uppercase;
  margin-bottom: 4rem;

  @media (min-width: 768px) {
    margin-bottom: 5rem;
  }

  .header {
    font-family: var(--latoFont);
    text-align: left;
    letter-spacing: 5px;
    line-height: 1.1;
    font-size: 2.2rem;

    @media (min-width: 768px) {
      font-size: 4rem;
    }
  }

  .title {
    color: #1a1a1a;
    color: var(--mainBlack);
  }

  span {
    display: inline-block;
    margin: 0 0.4rem;
    color: #a62121;
    color: var(--mainWine);
  }
`;

export default Title;
