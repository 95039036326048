import React from 'react';
import styled from 'styled-components';

import Logo from '../components/Logo';

import socialLink from '../constants/social-icons';
import links from '../constants/links';
import MobileNavigation from './MobileNavigation';

import { scrollToTargetAdjusted } from '../utility/scroll-to';

const Navbar = () => {
  return (
    <Nav>
      <h2 className="sr-only">Główna Nawigacja</h2>
      <div className="nav__inner">
        <Logo />
        <LinkList>
          {links.map((item) => {
            return (
              <li key={item.id}>
                <a
                  href={item.path}
                  title={item.text}
                  onClick={(event) => {
                    scrollToTargetAdjusted(event, item.id);
                  }}
                >
                  {item.text}
                </a>
              </li>
            );
          })}
        </LinkList>

        <div className="side-nav">
          <SocialList>
            {socialLink.map((item, index) => {
              return (
                <li key={index}>
                  <a
                    href={item.url}
                    title={item.title}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.icon}
                  </a>
                </li>
              );
            })}
          </SocialList>
          <MobileNavigation />
        </div>
      </div>
    </Nav>
  );
};

export default Navbar;

const Nav = styled.nav`
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 10px 0;
  background-color: #f2f2f2;
  background-color: var(--mainWhite);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

  .nav__inner {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .side-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1000;
  }
`;

const LinkList = styled.ul`
  li {
    display: inline-block;
  }

  @media (max-width: 767px) {
    display: none;
  }

  a {
    letter-spacing: var(--fontSpacing);
    border: 2px solid transparent;
    padding: 0.7rem 0.5rem;
    font-size: 1.6rem;
    display: inline-block;
    margin: 0 2px;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.3s linear;
    font-weight: 600;

    @media (min-width: 768px) {
      padding: 0.4rem 0.8rem;
      font-size: 1.3rem;
      letter-spacing: calc(var(--fontSpacing) / 2);
    }

    @media (min-width: 992px) {
      padding: 0.7rem 0.9rem;
      font-size: 1.6rem;
    }

    @media (min-width: 1200px) {
      padding: 0.9rem 1.5rem;
      font-size: 1.6rem;
    }

    &:link,
    &:visited {
      color: #402312;
      color: var(--mainDarkBrown);
    }

    &:hover,
    &:active,
    &.is-active {
      color: #a62121;
      color: var(--mainWine);
    }
  }
`;

const SocialList = styled.ul`
  position: relative;
  z-index: 2000;

  li {
    display: inline-block;
  }

  a {
    margin: 0 0.5rem;
    font-size: 3rem;
    transition: color 0.3s linear, transform 0.3s linear;
    cursor: pointer;
    transform: translateY(0);
    display: inline-block;

    &:link,
    &:visited {
      color: #402312;
      color: var(--mainDarkBrown);
    }

    &:hover,
    &:active,
    &.is-active {
      transform: translateY(-5px);
      color: #a62121;
      color: var(--mainWine);
    }
  }
`;
