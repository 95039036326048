import React, { useEffect } from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Banner from '../components/Home/Banner';
import About from '../components/Home/About';
import Offer from '../components/Home/Offer';
import Gallery from '../components/Home/Gallery';
import Contact from '../components/Home/Contact';
import Place from '../components/Home/Place';
import Seo from '../SEO';

import { scrollToTargetAdjusted } from '../utility/scroll-to';

const IndexPage = () => {
  useEffect(() => {
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window['IntersectionObserverEntry']['prototype']
    ) {
      const sections = document.querySelectorAll('section');
      const links = document.querySelectorAll('nav li a');

      const config = {
        rootMargin: '-49% 49%',
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            const target = entry.target.getAttribute('id');

            links.forEach((item) => {
              item.classList.remove('is-active');
            });
            const filtredArray = Array.from(links).filter(
              (item) => item.getAttribute('href').indexOf(target) > -1
            );
            for (const item of filtredArray) {
              item.classList.add('is-active');
            }
          }
        });
      }, config);

      sections.forEach((section) => {
        observer.observe(section);
      });
    }
  }, []);

  return (
    <Layout>
      <Seo />
      <Banner>
        <MainTitle>Winnica Rytwiany</MainTitle>
        <MainDescription>
          Nasze wina to oddech ziemi i&nbsp;uśmiech słońca,
          <br /> a&nbsp;w&nbsp;połączeniu:&nbsp;zdrowie i&nbsp;szczęście.
        </MainDescription>
        <button
          type="button"
          className="btn"
          title="Dowiedz się więcej o winnicy"
          onClick={(event) => {
            scrollToTargetAdjusted(event, 'offer');
          }}
        >
          Dowiedz się więcej
        </button>
      </Banner>
      <Offer />
      <About />
      <Gallery />
      <Contact />
      <Place />
    </Layout>
  );
};

const MainTitle = styled.h2`
  color: #f2f2f2;
  color: var(--mainWhite);
  margin-bottom: 3rem;
  line-height: 1.2;
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 6px;

  @media (min-width: 768px) {
    font-size: 6rem;
    margin-bottom: 1.8rem;
    line-height: 1.4;
  }

  @media (min-width: 992px) {
    font-size: 7.5rem;
  }
`;

const MainDescription = styled.p`
  color: #f2f2f2;
  color: var(--mainWhite);
  font-size: 1.6rem;
  max-width: 800px;
  margin: 0 auto 3rem;

  br {
    display: none;
  }

  @media (min-width: 540px) {
    br {
      display: inline;
    }
  }

  @media (min-width: 768px) {
    font-size: 1.8rem;
    margin: 0 auto 4rem;
  }
`;

export default IndexPage;
