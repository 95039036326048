import React, { useState } from 'react';
import styled from 'styled-components';

import links from '../constants/links';
import { scrollToTargetAdjusted } from '../utility/scroll-to';

const MobileNavigation = () => {
  const [isOpen, setOpenStatus] = useState(false);

  const clickHandler = () => {
    setOpenStatus(!isOpen);
    document.body.parentElement.classList.toggle('is-mobile-menu');
  };

  const onClickHandler = (event, target) => {
    scrollToTargetAdjusted(event, target);
    clickHandler();
  };

  return (
    <MobileWrapper>
      <MobileMenu className={isOpen ? 'is-active' : ''}>
        {links.map((item) => {
          return (
            <li key={item.id}>
              <a
                href={item.path}
                title={item.text}
                onClick={(event) => {
                  onClickHandler(event, item.id);
                }}
              >
                {item.text}
              </a>
            </li>
          );
        })}
      </MobileMenu>

      <Overlay
        className={isOpen ? 'is-active' : ''}
        onClick={clickHandler}
      ></Overlay>

      <HamburgerWrapper
        id="hamburger"
        className={`hamburger hamburger--squeeze ${isOpen ? 'is-active' : ''}`}
        type="button"
        onClick={clickHandler}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </HamburgerWrapper>
    </MobileWrapper>
  );
};

export default MobileNavigation;

const MobileWrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileMenu = styled.ul`
  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    right: -290px;
    background-color: var(--mainWhite);
    z-index: 1100;
    list-style: none;
    padding: 100px 15px 15px;
    min-width: 280px;
    height: 100vh;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
      rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-property: right;

    &.is-active {
      right: 0;
    }

    a {
      letter-spacing: var(--fontSpacing);
      border: 2px solid transparent;
      padding: 0.9rem 1rem;
      font-size: 1.6rem;
      display: inline-block;
      margin: 0 2px;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
      transition: color 0.3s linear;
      font-weight: 600;

      &:link,
      &:visited {
        color: var(--mainDarkBrown);
      }

      &:hover,
      &:active,
      &.is-active {
        color: var(--mainWine);
      }
    }
  }
`;

const Overlay = styled.div`
  @media (max-width: 767px) {
    position: fixed;
    z-index: 900;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-property: opacity;

    &.is-active {
      opacity: 1;
      pointer-events: auto;
      cursor: pointer;
    }
  }
`;

const HamburgerWrapper = styled.button`
  /*!
    * Hamburgers
    * @description Tasty CSS-animated hamburgers
    * @author Jonathan Suh @jonsuh
    * @site https://jonsuh.com/hamburgers
    * @link https://github.com/jonsuh/hamburgers
    */

  display: none;

  @media (max-width: 767px) {
    outline: none;
    padding: 15px 15px 15px 45px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    position: relative;
    z-index: 2000;

    &.is-active {
      .hamburger-inner {
        &,
        &:before,
        &:after {
          background-color: var(--mainBlack);
        }
      }
    }

    .hamburger-box {
      width: 40px;
      height: 24px;
      display: inline-block;
      position: relative;
    }

    .hamburger-inner {
      display: block;
      top: 50%;
      margin-top: -2px;
    }

    .hamburger-inner {
      &,
      &:before,
      &:after {
        width: 40px;
        height: 4px;
        background-color: #000;
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
      }

      &:before,
      &:after {
        content: '';
        display: block;
      }

      &:before {
        top: -10px;
      }

      &:after {
        bottom: -10px;
      }
    }

    &.hamburger--squeeze {
      .hamburger-inner {
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &:before {
          transition: top 0.075s 0.12s ease, opacity 0.075s ease;
        }

        &:after {
          transition: bottom 0.075s 0.12s ease,
            transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
      }

      &.is-active {
        .hamburger-inner {
          transform: rotate(45deg);
          transition-delay: 0.12s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

          &:before {
            top: 0;
            opacity: 0;
            transition: top 0.075s ease, opacity 0.075s 0.12s ease;
          }

          &:after {
            bottom: 0;
            transform: rotate(-90deg);
            transition: bottom 0.075s ease,
              transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
        }
      }
    }
  }
`;
