import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <FooterWrapper>{`© ${new Date().getFullYear()} Winnica Rytwiany`}</FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  text-align: center;
  color: var(--mainWhite);
  background-color: var(--mainBlack);
  padding: 10px 15px;
  font-size: 1.4rem;
`;

export default Footer;
