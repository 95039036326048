import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const query = graphql`
  query {
    default: file(relativePath: { eq: "banner.jpg" }) {
      placeholderImage: childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;

const Banner = ({ className, children }) => {
  const data = useStaticQuery(query);
  const image = getImage(data.default.placeholderImage);
  // duotone: { highlight: "#f00e2e", shadow: "#192550" }
  // Use like this:
  const bgImage = convertToBgImage(image);
  return (
    <section id="home">
      <BackgroundImage
        className={className}
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        backgroundColor={`#f00e2e`}
      >
        <div>
          <GatsbyImage image={image} loading="eager" alt={''} />
        </div>
        <div className="content">{children}</div>
      </BackgroundImage>
    </section>
  );
};

export default styled(Banner)`
  min-height: calc(100vh - 84px);
  height: calc(100vh - 84px);
  background: linear-gradient(rgba(131, 2, 2, 0.8), rgba(71, 1, 1, 0.75));
  background-position: center;
  background-size: cover;
  opacity: 1 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;

  .content {
    width: 100%;
    max-width: 1200px;
    padding: 25px 15px;
    margin: 0 auto;
    text-align: center;
  }
`;
