import React from 'react';
import styled from 'styled-components';

import {
  GiWineBottle,
  GiTreeBranch,
  GiPhotoCamera,
  GiBarrel,
} from 'react-icons/gi';

import Title from '../Title';
import Separator from '../Separator';

const Offer = () => {
  return (
    <SectionWrapper className="container" id="offer">
      <Title title="Nasza " subtitle="Oferta" />

      <div className="offers">
        <div className="offer">
          <GiBarrel className="offer__icon"></GiBarrel>
          <h3 className="offer__name">Zwiedzanie winnicy oraz winiarni</h3>
        </div>
        <div className="offer">
          <GiWineBottle className="offer__icon"></GiWineBottle>
          <h3 className="offer__name">Sprzedaż i&nbsp;degustacja wina</h3>
        </div>
        <div className="offer">
          <GiTreeBranch className="offer__icon"></GiTreeBranch>
          <h3 className="offer__name">
            Zwiedzanie przydomowego ogrodu botanicznego oraz spacer po prywatnym
            lesie
          </h3>
        </div>
        <div className="offer">
          <GiPhotoCamera className="offer__icon"></GiPhotoCamera>
          <h3 className="offer__name">
            Udostępnienie terenu pod sesje zdjęciowe
          </h3>
        </div>
      </div>

      <div className="pricelist">
        <h3 className="pricelist__title">Cennik</h3>
        <ul>
          <li>
            <span className="pricelist__name">
              Froge
              <span className="pricelist__detail">
                (wino czerwone, wytrawne
                <span className="less">
                  , cuvee odmian Frontenac oraz Regent
                </span>
                )
              </span>
            </span>
            <span className="pricelist__dots"></span>
            <span className="pricelist__price">60zł</span>
          </li>
          <li>
            <span className="pricelist__name">
              Solwiis
              <span className="pricelist__detail">
                (wino białe, półsłodkie<span className="less">, Solaris</span>)
              </span>
            </span>
            <span className="pricelist__dots"></span>
            <span className="pricelist__price">60zł</span>
          </li>
          <li>
            <span className="pricelist__name">
              Seybl
              <span className="pricelist__detail">
                (wino białe, półsłodkie
                <span className="less">, Seybal BLANC A</span>)
              </span>
            </span>
            <span className="pricelist__dots"></span>
            <span className="pricelist__price">60zł</span>
          </li>
          <li>
            <span className="pricelist__name">
              Reson
              <span className="pricelist__detail">
                (wino białe, półwytrawne
                <span className="less">, Swenson Red</span>)
              </span>
            </span>
            <span className="pricelist__dots"></span>
            <span className="pricelist__price">60zł</span>
          </li>
          <li>
            <span className="pricelist__name">
              Le Mar
              <span className="pricelist__detail">
                (wino czerwone, wytrawne
                <span className="less">
                  , cuvee odmian Leon Millot oraz Marechal Foch
                </span>
                )
              </span>
            </span>
            <span className="pricelist__dots"></span>
            <span className="pricelist__price">60zł</span>
          </li>
          <li>
            <span className="pricelist__name">Zwiedzanie winnicy</span>
            <span className="pricelist__dots"></span>
            <span className="pricelist__price">10zł/os</span>
          </li>
          <li>
            <span className="pricelist__name">
              Zwiedzanie z&nbsp;degustacją
            </span>
            <span className="pricelist__dots"></span>
            <span className="pricelist__price">25zł/os</span>
          </li>
        </ul>
      </div>

      <div className="legal" style={{ marginBottom: '20px' }}>
        Winnica oferuje również noclegi w&nbsp;przyczepach kempingowych
        2&nbsp;i&nbsp;4&nbsp;osobowych.
      </div>

      <div className="legal">
        Wina wyprodukowane z&nbsp;własnych upraw winorośli z&nbsp;poszanowaniem
        przyrody i&nbsp;wiekowych tradycji manufaktury.
      </div>

      <Separator />
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  .offers {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    padding: 5rem 0 0;
    margin-bottom: 5rem;

    @media (min-width: 576px) {
      flex-direction: row;
    }
  }

  .offer {
    padding: 0 1rem;
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;

    @media (min-width: 576px) {
      max-width: 50%;
    }

    @media (min-width: 992px) {
      max-width: 25%;
    }
  }

  .offer__icon {
    font-size: 6rem;
    margin-bottom: 1rem;
    color: #a62121;
    color: var(--mainWine);
  }

  .offer__name {
    color: #1a1a1a;
    color: var(--mainBlack);
    letter-spacing: 1px;
    font-size: 1.4rem;
  }

  .pricelist {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 5rem;
  }

  .pricelist__title {
    font-size: 3rem;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    font-family: var(--latoFont);
    margin-bottom: 1rem;
  }

  .pricelist__name {
    flex: 0 0 auto;
    font-size: 1.6rem;
    font-weight: 600;

    @media (min-width: 480px) {
      font-size: 2rem;
    }
  }

  .pricelist__detail {
    display: block;
    font-size: 1.2rem;
    font-weight: 400;

    @media (min-width: 480px) {
      font-size: 1.4rem;
    }
  }

  .less {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .pricelist__dots {
    border-bottom: 2px dotted #a62121;
    border-bottom: 2px dotted var(--mainWine);
    width: 100%;
    margin: 0 0.3rem 0.3rem;
  }

  .pricelist__price {
    flex: 0 0 auto;
    align-self: flex-end;
    font-size: 1.6rem;

    @media (min-width: 480px) {
      font-size: 2rem;
      min-width: 70px;
    }
  }

  .legal {
    font-size: 1.6rem;
    margin: 0 auto;
    text-align: center;
    font-weight: 600;
  }
`;

export default Offer;
