import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa';

import Title from '../Title';

const AnyReactComponent = () => <FaMapMarkerAlt className="marker" />;

const Place = () => {
  const defaultProps = {
    center: {
      lat: 50.531191,
      lng: 21.1957501,
    },
    zoom: 12,
  };

  return (
    <SectionWrapper id="place">
      <div className="container">
        <Title title="Jak " subtitle="Dojechać" />

        <address>
          {/* <h4>Nasz Adres:</h4> */}
          <p>Tadeusza Kościuszki 41</p>
          <p>28-236 Rytwiany</p>
        </address>
      </div>

      <div style={{ height: '65vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDEvcbWjFUGD463AuQBesglFNq1UHL26_g' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent
            lat={defaultProps.center.lat}
            lng={defaultProps.center.lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  address {
    width: 100%;
    max-width: 180px;
    margin: 0 0 3rem;
    padding: 2rem 0;

    h4 {
      font-size: 2rem;
    }

    p {
      font-size: 1.6rem;
    }
  }

  .marker {
    color: var(--mainWine);
    font-size: 3rem;
    transform: translate(-50%, -100%);
  }
`;

export default Place;
