import React from 'react';
import styled from 'styled-components';
import { GiGrapes } from 'react-icons/gi';

const Separator = () => {
  return (
    <SeparatorWrapper>
      <div className="icon">
        <GiGrapes />
      </div>
    </SeparatorWrapper>
  );
};

export default Separator;

const SeparatorWrapper = styled.div`
  overflow: hidden;

  .icon {
    font-size: 5rem;
    border: 1px solid #737373;
    border: 1px solid var(--mainGray);
    color: #737373;
    color: var(--mainGray);
    border-radius: 50%;
    padding: 10px;
    line-height: 1;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem auto 4rem;
    position: relative;
    opacity: 0.7;

    @media (min-width: 768px) {
      margin: 5rem auto 8rem;
      width: 80px;
      height: 80px;
      padding: 15px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      opacity: 0.7;
      top: 50%;
      width: 99999px;
      height: 1px;
      background-color: #737373;
      background-color: var(--mainGray);
    }

    &:before {
      right: 135%;
    }

    &:after {
      left: 135%;
    }
  }
`;
