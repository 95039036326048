import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Title from '../Title';
import Separator from '../Separator';

const About = () => {
  return (
    <SectionWrapper className="container" id="about">
      <Title title="O " subtitle="Nas" />

      <div className="wrapper">
        <figure>
          <StaticImage
            src="../../images/about-us.jpg"
            placeholder="TRACED_SVG"
            layout="fullWidth"
            quality={90}
            loading="eager"
            alt="Rodzina"
          />
          <div className="frame"></div>
        </figure>
        <div className="content">
          <h3 className="subtitle">Winiarstwo to nasza pasja</h3>
          <p>
            Winnica Rytwiany — nazwa naszej winnicy wywodzi się
            od&nbsp;miejscowości, w&nbsp;której jest usytuowana. Rytwiany
            położone są&nbsp;w&nbsp;województwie świętokrzyskim. Posiadają
            piękną i&nbsp;bogatą historię oraz wiele związanych z&nbsp;nią
            zabytków. Poczynając od&nbsp;ludzi neolitu przez kontrowersyjnego
            rycerza Dersława, po&nbsp;ekranizację serialu „Czarne Chmury”,
            którego akcja toczy się na&nbsp;terenie XVII wiecznego klasztoru
            pokamedulskiego; obecnie teren „Pustelni Złotego Lasu”.
          </p>
          <p>
            Winnica Rytwiany została założona w&nbsp;2016&nbsp;roku, bazując
            na&nbsp;rodzinnej pasji. Posiada 3000&nbsp;tysiące nasadzeń
            z&nbsp;7&nbsp;odmian winorośli. Wytwarzamy wina białe
            i&nbsp;czerwone, od półsłodkich po wytrawne. Praca w winnicy
            to&nbsp;manufaktura, a&nbsp;efektem jej są&nbsp;wysokiej jakości
            wina doceniane przede wszystkim wśród konsumentów.
          </p>
          <p>
            <strong>Zapraszamy do zwiedzania i&nbsp;degustacji.</strong>
          </p>
        </div>
      </div>
      <Separator />
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  .wrapper {
    display: flex;
    padding-right: 15px;
  }

  figure {
    display: none;

    @media (min-width: 768px) {
      display: block;
      width: 100%;
      max-width: 35%;
    }

    @media (min-width: 992px) {
      max-width: 40%;
    }
  }

  .content {
    width: 100%;
    max-width: 100%;

    @media (min-width: 768px) {
      max-width: 65%;
      padding-top: 5%;
      padding-left: 5%;
    }

    @media (min-width: 992px) {
      max-width: 60%;
    }
  }

  .subtitle {
    margin-bottom: 2.5rem;
    text-transform: uppercase;
    font-size: 2rem;

    @media (min-width: 768px) {
      font-size: 2.5rem;
    }
  }

  p {
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 1.6rem;
  }
`;

export default About;
