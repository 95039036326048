import React, { Component } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StaticQuery, graphql } from 'gatsby';

import 'slick-carousel/slick/slick.css';

import Slider from 'react-slick';

const getImages = graphql`
  query {
    main: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 95, placeholder: TRACED_SVG)
          }
        }
      }
    }

    thumb: allFile(
      filter: { relativeDirectory: { eq: "gallery/thumb" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 95, placeholder: TRACED_SVG)
          }
        }
      }
    }
  }
`;

export default class SlickGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const mainSettings = {
      adaptiveHeight: true,
    };

    const thumbSettings = {
      slidesToShow: 12,
      swipeToSlide: true,
      focusOnSelect: true,
      arrows: false,

      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 10,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 8,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 6,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
    };

    return (
      <div>
        <StaticQuery
          query={getImages}
          render={(data) => {
            const mainImages = data.main.edges;
            const thumbImages = data.thumb.edges;

            return (
              <>
                <Slider
                  asNavFor={this.state.nav2}
                  {...mainSettings}
                  ref={(slider) => (this.slider1 = slider)}
                  className="main-slider"
                >
                  {mainImages.map(
                    (
                      {
                        node: {
                          childImageSharp: { gatsbyImageData: item },
                        },
                      },
                      idx
                    ) => {
                      return (
                        <div className="item" key={idx}>
                          <GatsbyImage image={item} alt="" />
                        </div>
                      );
                    }
                  )}
                </Slider>

                <Slider
                  asNavFor={this.state.nav1}
                  ref={(slider) => (this.slider2 = slider)}
                  {...thumbSettings}
                  className="thumb-slider"
                >
                  {thumbImages.map(
                    (
                      {
                        node: {
                          childImageSharp: { gatsbyImageData: item },
                        },
                      },
                      idx
                    ) => {
                      return (
                        <div className="item" key={idx}>
                          <GatsbyImage image={item} alt="" />
                        </div>
                      );
                    }
                  )}
                </Slider>
              </>
            );
          }}
        />
      </div>
    );
  }
}
