import React from 'react';
import { FaFacebook } from 'react-icons/fa';

const Socials = [
  {
    icon: <FaFacebook />,
    url: '//www.facebook.com/winnicarytwiany/',
    title: 'Facebook',
  },
];

export default Socials;
