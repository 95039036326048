const Links = [
  {
    path: '#home',
    text: 'Home',
    id: 'home',
  },
  {
    path: '#offer',
    text: 'Oferta',
    id: 'offer',
  },
  {
    path: '#about',
    text: 'O Nas',
    id: 'about',
  },
  {
    path: '#gallery',
    text: 'Galeria',
    id: 'gallery',
  },
  {
    path: '#contact',
    text: 'Kontakt',
    id: 'contact',
  },
  {
    path: '#place',
    text: 'Jak dojechać',
    id: 'place',
  },
];

export default Links;
