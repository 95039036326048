import React from 'react';
import styled from 'styled-components';

import Title from '../Title';
import Separator from '../Separator';

import { FaPhone, FaEnvelopeOpen, FaFacebook } from 'react-icons/fa';

const Contact = () => {
  return (
    <SectionWrapper className="container" id="contact">
      <Title title="Skontaktuj się " subtitle="Z nami" />

      <div className="contact-wrapper">
        <div className="contact-detail">
          <a href="tel:883328545" target="_blank" rel="noopener noreferrer">
            <span>
              <FaPhone />
            </span>
            883-328-545
          </a>
          <a
            href="mailto:winnicarytwiany@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FaEnvelopeOpen />
            </span>
            winnicarytwiany@gmail.com
          </a>
          <a
            href="//www.facebook.com/winnicarytwiany/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <FaFacebook />
            </span>
            @winnicarytwiany
          </a>
        </div>

        {/* <Title title="Napisz " subtitle="Do Nas" /> */}
        <div className="form-content">
          <form
            action="//formspree.io/winnicarytwiany@gmail.com"
            method="POST"
            className="form"
          >
            <div className="form-group">
              <label htmlFor="name">Imię i Nazwisko</label>
              <input
                className="form-input"
                type="text"
                name="name"
                id="name"
                placeholder="Jan Nowak"
                required
                minLength="3"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                className="form-input"
                type="email"
                name="email"
                id="email"
                placeholder="email@email.com"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Wiadomość</label>
              <textarea
                className="form-input"
                name="message"
                id="message"
                placeholder="Twoja wiadomość"
                required
                minLength="5"
                rows="7"
              ></textarea>
              <input type="text" name="_gotcha" style={{ display: 'none' }} />
            </div>
            <div className="form-group">
              <input className="form-button btn" type="submit" value="Wyślij" />
            </div>
          </form>
        </div>
      </div>
      <Separator />
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  .contact-wrapper {
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .form-content {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 768px) {
      order: 1;
      max-width: 55%;
      margin: 0;
    }
  }

  .form-group {
    margin-bottom: 1.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  label {
    display: block;
    margin-bottom: 0.2rem;
    font-size: 1.4rem;
    font-weight: 600;
  }

  .form-input {
    width: 100%;
    padding: 9px 15px;
    font-size: 1.6rem;
    border-color: #8c5627;
    border: 1px solid var(--mainBrown);
    box-shadow: none;
    font-family: var(--latoFont);
  }

  .form-button {
    color: #8c5627;
    border-color: #8c5627;
    color: var(--mainBrown);
    border-color: var(--mainBrown);
    background-color: transparent;
    font-family: var(--latoFont);

    &:hover {
      color: #f2f2f2;
      border-color: #8c5627;
      background-color: #8c5627;
      color: var(--mainWhite);
      border-color: var(--mainBrown);
      background-color: var(--mainBrown);
    }
  }

  .contact-detail {
    width: 100%;
    max-width: 270px;
    margin: 0 auto 5rem;

    @media (min-width: 768px) {
      order: 2;
      max-width: 30%;
      margin-bottom: 3rem;
      padding-top: 2rem;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      transition: color 0.3s linear, background-color 0.3s linear;
      font-weight: 600;
      color: var(--mainWhite);
      display: block;
      font-size: 1.4rem;
      margin-bottom: 1.4rem;

      &:link,
      &:visited {
        color: #1a1a1a;
        color: var(--mainBlack);
      }

      &:focus {
        transition: none;
        color: #a62121;
        color: var(--mainWine);
      }

      &:hover,
      &:active,
      &.is-active {
        color: #a62121;
        color: var(--mainWine);
      }

      span {
        color: #a62121;
        color: var(--mainWine);
        font-size: 1.6rem;
        margin-right: 1.4rem;
        line-height: 1;
        position: relative;
        top: 2px;
      }
    }
  }
`;

export default Contact;
