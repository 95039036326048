import React from 'react';

import Navbar from './Navbar';
import Footer from './Footer';

import './Layout.scss';

const Layout = ({ children }) => {
  return (
    <>
      <h1 className="sr-only">Winnica Rytwiany</h1>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
