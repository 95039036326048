const Easings = {
  linear(t) {
    return t;
  },
  easeInQuad(t) {
    return t * t;
  },
  easeOutQuad(t) {
    return t * (2 - t);
  },
  easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  },
  easeInCubic(t) {
    return t * t * t;
  },
  easeOutCubic(t) {
    return --t * t * t + 1;
  },
  easeInOutCubic(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  },
  easeInQuart(t) {
    return t * t * t * t;
  },
  easeOutQuart(t) {
    return 1 - --t * t * t * t;
  },
  easeInOutQuart(t) {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
  },
  easeInQuint(t) {
    return t * t * t * t * t;
  },
  easeOutQuint(t) {
    return 1 + --t * t * t * t * t;
  },
  easeInOutQuint(t) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  },
};

class ScrollTo {
  _defaultSettings = {
    duration: 350,
    offset: 0,
    easing: 'easeInOutQuad',
    onEnd: null,
    onStart: null,
    onStartParams: [],
    onEndParams: [],
    onBreak: null,
    onBreakParams: [],
  };

  _raf = null;
  _isMoved = false;
  _start = null;
  _startTime = null;
  _easings = Easings;
  _isFinished = false;

  static instance = null;

  static constructor() {}

  static getInstance() {
    if (this.instance) {
      return this.instance;
    }
    this.instance = new ScrollTo();
    return this.instance;
  }

  _clearRaf = () => {
    if (
      !this._isFinished &&
      this.settings.onBreak &&
      typeof this.settings.onBreak === 'function'
    ) {
      const arg = this.settings.onBreakParams
        ? this.settings.onBreakParams
        : [];
      this.settings.onBreak(...arg);
    }

    window.cancelAnimationFrame(this.raf);
    window.removeEventListener('resize', this._clearRaf, false);
    window.removeEventListener('mousewheel', this._clearRaf, false);
    window.removeEventListener('DOMMouseScroll', this._clearRaf, false);
    this._isMoved = false;
  };

  _scroll(duration, easing, destinationOffsetToScroll, onEnd) {
    const now = window.performance.now();
    const time = Math.min(1, (now - this.startTime) / this.settings.duration);
    const timeFunction = this._easings[this.settings.easing](time);
    this._isMoved = true;

    window.scroll(
      0,
      Math.ceil(
        timeFunction * (destinationOffsetToScroll - this.start) + this.start
      )
    );
    if (
      window.pageYOffset === destinationOffsetToScroll ||
      Math.abs(window.pageYOffset - destinationOffsetToScroll) <= 1 ||
      (window.pageYOffset <= 0 && destinationOffsetToScroll < 0)
    ) {
      this._isFinished = true;

      if (
        this._isMoved &&
        this.settings.onEnd &&
        typeof this.settings.onEnd === 'function'
      ) {
        const arg = this.settings.onEndParams ? this.settings.onEndParams : [];
        this.settings.onEnd(...arg);
      }
      this._isMoved = false;
      return;
    }

    this._isMoved = true;

    this.raf = window.requestAnimationFrame(() => {
      this._scroll(duration, easing, destinationOffsetToScroll, onEnd);
    });
  }

  scrollTo(target, config) {
    this.settings = {
      ...this._defaultSettings,
      ...config,
    };

    if (this.settings.onStart && typeof this.settings.onStart === 'function') {
      const arg = this.settings.onStartParams
        ? this.settings.onStartParams
        : [];
      this.settings.onStart(...arg);
    }

    if (this._isMoved) {
      this._clearRaf();
    }

    window.addEventListener('resize', this._clearRaf, false);
    window.addEventListener('mousewheel', this._clearRaf, false);
    window.addEventListener('DOMMouseScroll', this._clearRaf, false);

    this.start = window.pageYOffset;
    this.startTime = window.performance.now();

    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );

    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    const destinationOffset =
      (typeof target === 'number' ? target : target.offsetTop) +
      this.settings.offset;
    const destinationOffsetToScroll = Math.round(
      documentHeight - destinationOffset < windowHeight
        ? documentHeight - windowHeight
        : destinationOffset
    );

    this._scroll(
      this.settings.duration,
      this.settings.easing,
      destinationOffsetToScroll,
      this.settings.onEnd
    );
  }
}

export const scrollToTargetAdjusted = (event, target, offset = -120) => {
  const element = document.getElementById(target);

  if (!element) {
    return true;
  }

  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window['IntersectionObserverEntry']['prototype']
  ) {
  } else {
    offset = -30;
  }

  event.preventDefault();

  const s = ScrollTo.getInstance();

  s.scrollTo(element, {
    duration: 300,
    offset: offset,
    easing: 'easeOutQuad',
  });

  return false;
};
