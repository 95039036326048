import React from 'react';
import styled from 'styled-components';
// import ImageGallery from 'react-image-gallery';

// import images from '../../constants/galleryImage';

import Title from '../Title';
import Separator from '../Separator';
import SlickGallery from '../SlickGallery';

const Gallery = () => {
  return (
    <GalleryWrapper id="gallery" className="container">
      <Title title="Nasze " subtitle="Fotorelacje" />

      {/* <ImageGallery items={images} lazyLoad={true} /> */}
      <SlickGallery />
      <Separator />
    </GalleryWrapper>
  );
};

export default Gallery;

const GalleryWrapper = styled.section`
  /* margin-bottom: 7rem; */
  overflow: hidden;
`;
